import { Badge, Dropdown, Image, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import ThreeDotExpandIcon from "assets/icons/ThreeDotExpandIcon.svg";
import CustomMutipleTagsDisplay from "components/CustomMutipleTagsDisplay";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { UPDATE_DATE_FORMAT } from "constants/constants";
import dayjs from "dayjs";
import { UserRole } from "interfaces/common-model";
import { DepotFormStatus } from "pages/depot-management/interfaces";
import { useUserRole } from "pages/eod-management/components/SelectRole";
import { useMemo } from "react";
import { Account } from "../interfaces";

type UseTableColumnsParams = {
  isInternal: boolean;
  handleActions: (event: any, record: Account) => void; //yes it is any event
};

export const useTableAccountsColumns = ({
  isInternal,
  handleActions,
}: UseTableColumnsParams) => {
  const role = useUserRole(true);

  const itemsCustom = (record: Account) => {
    const actions = isInternal
      ? [
          { key: "edit", label: "Edit", disabled: false },
          {
            key: "assign_role",
            label: "Assign Role",
            disabled: false,
          },
        ]
      : [{ key: "edit", label: "Edit", disabled: false }];
    return actions.map(({ key, label, disabled }) => ({
      disabled,
      key,
      label: (
        <Typography.Text className="font-roboto font-normal text-base leading-6 text-blue-sapphire">
          {label}
        </Typography.Text>
      ),
    }));
  };

  const columns: ColumnsType<Account> = useMemo(
    () => [
      {
        title: <TableHeaderTitle customClassName="pl-5" title="CCGID" />,
        dataIndex: "ccgid",
        ellipsis: true,
        width: 100,
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex items-center gap-3">
            <CustomTooltip
              isShowCopy={false}
              copytext={
                record.isActive
                  ? DepotFormStatus.Active
                  : DepotFormStatus.Inactive
              }
            >
              <Badge
                color={record.isActive ? "#00865D" : "#D9DCE8"}
                className="[&_span]:!w-2 [&_span]:!h-2"
              />
            </CustomTooltip>
            <CustomTooltip isShowCopy={true} copytext={record.ccgid}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.ccgid}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="First Name" />,
        dataIndex: "firstName",
        width: 100,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col">
            <CustomTooltip isShowCopy={true} copytext={record.firstName}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.firstName}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="Last Name" />,
        dataIndex: "lastName",
        width: 100,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => a.lastName?.localeCompare(b.lastName),
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col">
            <CustomTooltip isShowCopy={true} copytext={record.lastName}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.lastName}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="Role" />,
        dataIndex: "roleName",
        width: 100,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => {
          const roleNameA = a.roleName || "";
          const roleNameB = b.roleName || "";
          return roleNameA?.localeCompare(roleNameB);
        },
        render: (_value: any, record: Account, _index: number) => (
          <CustomMutipleTagsDisplay
            tags={record.roles}
            numberOfShowedTags={1}
          />
        ),
      },
      {
        title: <TableHeaderTitle title="Country" />,
        dataIndex: "countryName",
        width: 90,
        sortIcon: CustomSortIcon,
        sorter: (a, b) => {
          const countryNameA = a.countryName || "";
          const countryNameB = b.countryName || "";

          return countryNameA?.localeCompare(countryNameB);
        },
      },
      {
        title: <TableHeaderTitle title="Email" />,
        dataIndex: "email",
        sortIcon: CustomSortIcon,
        width: "20%",
        ellipsis: true,
        sorter: (a, b) => a.email?.localeCompare(b.email),
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col">
            <CustomTooltip isShowCopy={false} copytext={record.email}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.email}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
      },

      {
        title: <TableHeaderTitle title="Created By" />,
        sortIcon: CustomSortIcon,
        width: 90,
        sorter: (a, b) => {
          const createdByA = a.createdBy || "";
          const createdByB = b.createdBy || "";

          return createdByA?.localeCompare(createdByB);
        },
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col">
            <CustomTooltip isShowCopy={false} copytext={record.createdBy}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.createdBy}
              </Typography.Text>
            </CustomTooltip>
            <Typography.Text className="mt-[4px] font-roboto text-xs text-blue-waikawa-grey leading-4 font-normal">
              {record.createdDate &&
                dayjs(record.createdDate).format(UPDATE_DATE_FORMAT)}
            </Typography.Text>
          </div>
        ),
      },
      {
        title: <TableHeaderTitle title="Updated By" />,
        sortIcon: CustomSortIcon,
        width: 90,
        sorter: (a, b) => {
          const updatedByA = a.updatedBy || "";
          const updatedByB = b.updatedBy || "";

          return updatedByA?.localeCompare(updatedByB);
        },
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col">
            <CustomTooltip isShowCopy={false} copytext={record.updatedBy}>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                {record.updatedBy}
              </Typography.Text>
            </CustomTooltip>
            <Typography.Text className="mt-[4px] font-roboto text-xs text-blue-waikawa-grey leading-4 font-normal">
              {record.updatedDate &&
                dayjs(record.updatedDate).format(UPDATE_DATE_FORMAT)}
            </Typography.Text>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        width: 40,
        render: (_value: any, record: Account, _index: number) => (
          <div className="flex flex-col items-center justify-center w-full">
            <Dropdown
              placement="bottomLeft"
              trigger={["click"]}
              menu={{
                items: itemsCustom(record),
                onClick: (menuInfo) => handleActions(menuInfo, record),
                className:
                  "border border-solid border-blue-sapphire !rounded-none !p-0 shadow-none [&>li]:!rounded-none [&>li]:!px-3 [&>li]:!py-2 [&>li]:min-w-[8.25rem] [&>li:hover]:!bg-blue-solitude-light",
              }}
            >
              <Image
                preview={false}
                className="cursor-pointer !w-6 !h-6"
                src={ThreeDotExpandIcon}
                alt="edo-action-icon"
              />
            </Dropdown>
          </div>
        ),
      },
    ],
    [role]
  );

  return role !== UserRole.ITOfficer
    ? columns.filter((item: any) => item.dataIndex !== "action")
    : columns;
};
